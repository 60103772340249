import React from 'react'

import './LineIntroduction.css'

const LinesIntroduction = () => {
  return (
    <svg className="line" xmlns="http://www.w3.org/1999/xlink" width="3000" height="256.569" viewBox="0 0 3000 256.569" >
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" y1="-0.217" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#8f75eb"/>
          <stop offset="1" stopColor="#fff" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="0.5" y1="-0.452" x2="0.5" y2="1.296" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#3de4f2"/>
          <stop offset="1" stopColor="#fff" stopOpacity="0"/>
        </linearGradient>
      </defs>
      <g id="Component_22_4" data-name="Component 22 – 4" transform="translate(0.223 1.337)" opacity="0.2">
        <path id="União_1" data-name="União 1" d="M2299.331,513.671h-1932V446.34s112.093,29.418,219.088-11.753c124.327-47.841,256.357-167.25,428.959-167.25,64.926,0,117.907,5.7,163.346,15.032,255.907,52.539,290.569,122.175,457.031,152.218,213.187,38.476,293.546-69.616,663.428-75.293,244.766-3.757,233.16,43.214,457.163,41.728,436.281-2.895,577.279-181.718,827.4-133.685,168.689,32.395,221,87.232,428.958,167.249,107,41.171,219.088,11.754,219.088,11.754v67.331Z" transform="translate(-367.331 -258.439)" fill="url(#linear-gradient)"/>
        <path id="Path_960" data-name="Path 960" d="M3962.586,437.053c-171.1-2.706-218.037,41.265-370.342,61.88-316.507,42.84-420.166-105.582-779.187-60.137-79.925,10.117-49.772,14.305-231.1,47.15-297.058,53.806-449.264,81.812-554.081,39.432-94.584-38.244-108.955-80.458-234.25-127.451-39.059-14.649-90.142-24.007-165.669-23.24-251.5,2.553-296.332,174.133-617.748,174.133-172.6,0-212.949-94.712-373.669-112.474s-269.21,41.426-269.21,41.426V626.349h1932v.709H4231.8V478.479A1007.884,1007.884,0,0,0,3962.586,437.053Z" transform="translate(-367.331 -371.826)" fill="url(#linear-gradient-2)"/>
        <path id="União_1-2" data-name="União 1" d="M367.537,429.517s112.093,26.774,219.089-10.7C710.952,375.279,842.982,266.6,1015.584,266.6c64.927,0,117.908,5.19,163.346,13.681C1434.838,328.1,1469.5,391.477,1635.962,418.82c213.187,35.018,293.546-63.359,663.428-68.526,244.766-3.42,233.16,39.331,457.163,37.978,436.281-2.635,577.278-165.387,827.4-121.67,168.689,29.483,221,79.392,428.958,152.218,107,37.471,219.088,10.7,219.088,10.7v1.132" transform="translate(-367.331 -259.147)" fill="none" stroke="#d07bed" strokeMiterlimit="10" strokeWidth="3"/>
        <path id="Path_961" data-name="Path 961" d="M4232,429.022v-.665a1790.364,1790.364,0,0,0-269.21-22.637c-171.1-1.478-218.036,22.549-370.341,33.814-316.507,23.409-420.166-57.694-779.187-32.862-79.925,5.529-49.772,7.817-231.1,25.765-297.057,29.4-449.263,44.705-554.081,21.547-94.584-20.9-108.954-43.965-234.25-69.644-39.058-8.005-90.142-13.119-165.669-12.7-251.5,1.4-296.332,95.153-617.748,95.153-172.6,0-212.948-51.754-373.669-61.46s-269.21,22.637-269.21,22.637" transform="translate(-367.331 -291.943)" fill="none" stroke="#0081df" strokeMiterlimit="10" strokeWidth="3"/>
      </g>
    </svg>

  )
}

export default LinesIntroduction;